const logs = {
    title: 'Registos de auditoria',
    subtitle: 'Visualize logs de autenticação feitos por administradores e utilizadores',
    event: 'Evento',
    user: 'Utilizador',
    application: 'Aplicação',
    time: 'Hora',
    filter_by: 'Filtrar pors',
};
export default logs;
