const demo_app = {
    notification: 'Nutze dein existierendes Admin Konto oder erstelle ein neues Konto um dich in die Demo App einzuloggen.',
    title: 'Du hast dich erfolgreich in der Demo App angemeldet!',
    subtitle: 'Here is your log in information:',
    username: 'Benutzername: ',
    user_id: 'Benutzer ID: ',
    sign_out: 'Aus der Demo App ausloggen',
    continue_explore: 'Oder weiter zum Entdecken',
    customize_sign_in_experience: 'Anmeldeoberfläche anpassen',
    enable_passwordless: 'Passwordless einschalten',
    add_social_connector: 'Social Connector hinzufügen',
};
export default demo_app;
