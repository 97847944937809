const logs = {
    title: 'Denetim Kayıtları',
    subtitle: 'Yöneticiniz ve kullanıcılarınız tarafından yapılan kimlik doğrulama olaylarının kayıt verilerini görüntüleyin',
    event: 'Durum',
    user: 'Kullanıcı',
    application: 'Uygulama',
    time: 'Süre',
    filter_by: 'Göre filtrele',
};
export default logs;
