@use '@/scss/underscore' as _;

.changePassword {
  border: 1px solid var(--color-divider);
  border-radius: 8px;
  padding: _.unit(4);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .description {
    font: var(--font-body-medium);
    color: var(--color-text);
    margin-right: _.unit(4);
  }
}
