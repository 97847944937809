const api_resource_details = {
    back_to_api_resources: 'API 리소스로 돌아가기',
    settings: 'Settings',
    settings_description: 'API resources, a.k.a. Resource Indicators, indicate the target services or resources to be requested, usually, a URI format variable representing the resource‘s identity.',
    token_expiration_time_in_seconds: '토큰 만료 시간 (초)',
    token_expiration_time_in_seconds_placeholder: '토큰 만료 시간을 입력해주세요',
    delete_description: '이 행동은 취소될 수 없어요. 해당 API 리소스가 영원히 삭제될거예요. 삭제를 하기 위해 API 리소스 이름 "<span>{{name}}</span>"을 입력해주세요.',
    enter_your_api_resource_name: 'API 리소스 이름을 입력해주세요.',
    api_resource_deleted: '{name}} API 리소스가 성공적으로 삭제되었어요.',
};
export default api_resource_details;
