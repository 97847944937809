const api_resource_details = {
    back_to_api_resources: 'Back to API resources',
    settings: 'Settings',
    settings_description: 'API resources, a.k.a. Resource Indicators, indicate the target services or resources to be requested, usually, a URI format variable representing the resource‘s identity.',
    token_expiration_time_in_seconds: 'Token expiration time (in seconds)',
    token_expiration_time_in_seconds_placeholder: 'Enter your token expiration time',
    delete_description: 'This action cannot be undone. It will permanently delete the API resource. Please enter the api resource name <span>{{name}}</span> to confirm.',
    enter_your_api_resource_name: 'Enter your API resource name',
    api_resource_deleted: 'The API Resource {{name}} has been successfully deleted',
};
export default api_resource_details;
