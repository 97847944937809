// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { oidcModelInstancePayloadGuard } from '../foundations/index.js';
const createGuard = z.object({
    modelName: z.string().max(64),
    id: z.string().max(128),
    payload: oidcModelInstancePayloadGuard,
    expiresAt: z.number(),
    consumedAt: z.number().nullable().optional(),
});
const guard = z.object({
    modelName: z.string().max(64),
    id: z.string().max(128),
    payload: oidcModelInstancePayloadGuard,
    expiresAt: z.number(),
    consumedAt: z.number().nullable(),
});
export const OidcModelInstances = Object.freeze({
    table: 'oidc_model_instances',
    tableSingular: 'oidc_model_instance',
    fields: {
        modelName: 'model_name',
        id: 'id',
        payload: 'payload',
        expiresAt: 'expires_at',
        consumedAt: 'consumed_at',
    },
    fieldKeys: ['modelName', 'id', 'payload', 'expiresAt', 'consumedAt'],
    createGuard,
    guard,
});
