const sign_in_exp = {
    title: 'Oturum Açma Deneyimi',
    description: 'Oturum açma kullanıcı arayüzünü markanıza uyacak şekilde özelleştirin ve gerçek zamanlı olarak görüntüleyin',
    tabs: {
        branding: 'Markalaşma',
        sign_up_and_sign_in: 'Sign up and Sign in',
        others: 'Diğerleri',
    },
    welcome: {
        title: 'Bu oturum açma deneyimini ilk kez tanımlıyorsunuz. Bu kılavuz, gerekli tüm ayarları yapmanıza ve hızlı bir şekilde başlamanıza yardımcı olacaktır.',
        get_started: 'Başla',
        apply_remind: 'Lütfen oturum açma deneyiminin bu hesap altındaki tüm uygulamalar için geçerli olacağını unutmayınız.',
        got_it: 'Anladım',
    },
    color: {
        title: 'RENK',
        primary_color: 'Marka rengi',
        dark_primary_color: 'Marka rengi (Koyu)',
        dark_mode: 'Koyu modu etkinleştir',
        dark_mode_description: 'Uygulamanız, markanızın rengine ve logo algoritmasına göre otomatik olarak oluşturulmuş bir koyu mod temasına sahip olacaktır. Özelleştirmekte özgürsünüz.',
        dark_mode_reset_tip: 'Marka rengine göre koyu mod rengini yeniden hesaplayınız.',
        reset: 'Yeniden hesapla',
    },
    branding: {
        title: 'MARKA ALANI',
        ui_style: 'Stil',
        styles: {
            logo_slogan: 'Sloganlı şekilde uygulama logosu',
            logo: 'Yalnızca uygulama logosu',
        },
        logo_image_url: 'Uygulama logosu resim URLi',
        logo_image_url_placeholder: 'https://your.cdn.domain/logo.png',
        dark_logo_image_url: 'Uygulama logosu resim URLi (Koyu)',
        dark_logo_image_url_placeholder: 'https://your.cdn.domain/logo-dark.png',
        slogan: 'Slogan',
        slogan_placeholder: 'Yaratıcılığınızı açığa çıkarın',
    },
    sign_up_and_sign_in: {
        identifiers: 'Sign-up identifiers',
        identifiers_email: 'Email address',
        identifiers_sms: 'Phone number',
        identifiers_username: 'Username',
        identifiers_email_or_sms: 'Email address or phone number',
        identifiers_none: 'Not applicable',
        and: 'and',
        or: 'or',
        sign_up: {
            title: 'SIGN UP',
            sign_up_identifier: 'Sign-up identifier',
            identifier_description: 'The sign-up identifier is required for account creation and must be included in your sign-in screen.',
            sign_up_authentication: 'Authentication setting for sign-up',
            authentication_description: 'All selected actions will be obligatory for users to complete the flow.',
            set_a_password_option: 'Create your password',
            verify_at_sign_up_option: 'Verify at sign-up',
            social_only_creation_description: '(This apply to social only account creation)', // UNTRANSLATED
        },
        sign_in: {
            title: 'SIGN IN',
            sign_in_identifier_and_auth: 'Identifier and authentication settings for sign-in',
            description: 'Users can sign in using any of the options available. Adjust the layout by drag and dropping below options.',
            add_sign_in_method: 'Add Sign-in Method',
            password_auth: 'Password',
            verification_code_auth: 'Verification code',
            auth_swap_tip: 'Swap the options below to determine which appears first in the flow.',
            require_auth_factor: 'You have to select at least one authentication factor.', // UNTRANSLATED
        },
        social_sign_in: {
            title: 'SOCIAL SIGN-IN',
            social_sign_in: 'Social sign-in',
            description: 'Depending on the mandatory identifier you set up, your user may be asked to provide an identifier when signing up via social connector.',
            add_social_connector: 'Link Social Connector',
            set_up_hint: {
                not_in_list: 'Not in the list?',
                set_up_more: 'Set up',
                go_to: 'other social connectors now.', // UNTRANSLATED
            },
        },
        tip: {
            set_a_password: 'A unique set of a password to your username is a must.',
            verify_at_sign_up: 'We currently only support verified email. Your user base may contain a large number of poor-quality email addresses if no validation.',
            password_auth: 'This is essential as you have enabled the option to set a password during the sign-up process.',
            verification_code_auth: 'This is essential as you have only enabled the option to provide verification code when signing up. You’re free to uncheck the box when password set-up is allowed at the sign-up process.',
            delete_sign_in_method: 'This is essential as you have selected {{identifier}} as a required identifier.', // UNTRANSLATED
        },
    },
    others: {
        terms_of_use: {
            title: 'KULLANIM KOŞULLARI',
            enable: 'Kullanım koşullarını etkinleştir',
            description: 'Ürününüzün kullanımına ilişkin yasal anlaşmaları ekleyin',
            terms_of_use: 'Kullanım koşulları',
            terms_of_use_placeholder: 'https://your.terms.of.use/',
            terms_of_use_tip: 'Kullanım koşulları URLi',
        },
        languages: {
            title: 'DİLLER',
            enable_auto_detect: 'Enable auto-detect',
            description: "Your software detects the user's locale setting and switches to the local language. You can add new languages by translating UI from English to another language.",
            manage_language: 'Manage language',
            default_language: 'Default language',
            default_language_description_auto: 'The default language will be used when the detected user language isn’t covered in the current language library.',
            default_language_description_fixed: 'When auto-detect is off, the default language is the only language your software will show. Turn on auto-detect for language extension.', // UNTRANSLATED
        },
        manage_language: {
            title: 'Manage language',
            subtitle: 'Localize the product experience by adding languages and translations. Your contribution can be set as the default language.',
            add_language: 'Add Language',
            logto_provided: 'Logto provided',
            key: 'Key',
            logto_source_values: 'Logto source values',
            custom_values: 'Custom values',
            clear_all_tip: 'Clear all values',
            unsaved_description: 'Changes won’t be saved if you leave this page without saving.',
            deletion_title: 'Do you want to delete the added language?',
            deletion_tip: 'Delete the language',
            deletion_description: 'After deletion, your users won’t be able to browse in that language again.',
            default_language_deletion_title: 'Default language can’t be deleted.',
            default_language_deletion_description: '{{language}} is set as your default language and can’t be deleted. ',
            got_it: 'Got It', // UNTRANSLATED
        },
        advanced_options: {
            title: 'GELİŞMİŞ OPSİYONLAR',
            enable_user_registration: 'Enable user registration',
            enable_user_registration_description: 'Enable or disallow user registration. Once disabled, users can still be added in the admin console but users can no longer establish accounts through the sign-in UI.', // UNTRANSLATED
        },
    },
    setup_warning: {
        no_connector: '',
        no_connector_sms: 'No SMS connector set-up yet. Until you finish configuring your SMS connector, you won’t be able to sign in.',
        no_connector_email: 'No email connector set-up yet. Until you finish configuring your email connector, you won’t be able to sign in.',
        no_connector_social: 'No social connector set-up yet. Until you finish configuring your social connector, you won’t be able to sign in.',
        no_added_social_connector: 'Şimdi birkaç social connector kurdunuz. Oturum açma deneyiminize bazı şeyler eklediğinizden emin olun.',
    },
    save_alert: {
        description: 'You are implementing new sign-in and sign-up procedures. All of your users may be affected by the new set-up. Are you sure to commit to the change?',
        before: 'Önce',
        after: 'Sonra',
        sign_up: 'Sign-up',
        sign_in: 'Sign-in',
        social: 'Social', // UNTRANSLATED
    },
    preview: {
        title: 'Oturum Açma Önizlemesi',
        dark: 'Koyu',
        light: 'Açık',
        native: 'Doğal',
        desktop_web: 'Masaüstü Web',
        mobile_web: 'Mobil Web',
    },
};
export default sign_in_exp;
