@use '@/scss/underscore' as _;

.container {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 60px;
  border: 1px solid var(--color-line-divider);
  display: flex;
  padding: _.unit(3) _.unit(8);
  justify-content: flex-end;
  background-color: var(--color-float);
  box-shadow: var(--shadow-3);
  border-radius: 12px 12px 0 0;

  > :not(:first-child) {
    margin-left: _.unit(3);
  }
}
