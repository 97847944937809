@use '@/scss/underscore' as _;

.base {
  box-shadow: var(--shadow-1);
}

tr.clickable {
  cursor: pointer;

  &:hover {
    background: var(--color-hover);
  }
}

.scrollable {
  max-height: 100%;
  background-color: var(--color-layer-1);
  border-radius: 12px;
  overflow-y: auto;
  padding-bottom: _.unit(2);

  table {
    border: none;
    box-shadow: none;

    thead {
      background: var(--color-layer-1);
      position: sticky;
      top: 0;
    }

    tbody {
      tr {
        &:last-child {
          td {
            border-bottom: unset;
          }
        }
      }
    }
  }
}

.empty {
  height: 100%;
}
