const dashboard = {
    title: '대시보드',
    description: '앱 사용자에 대한 개요를 볼 수 있어요.',
    total_users: '전체 사용자',
    total_users_tip: '전체 사용자',
    new_users_today: '새로운 사용자 (오늘)',
    new_users_today_tip: '오늘 앱에 새로 가입한 사용자',
    new_users_7_days: '새로운 사용자 (최근 7일)',
    new_users_7_days_tip: '최근 7일 동안 앱에 새로 가입한 사용자',
    daily_active_users: '활성화 사용자 (오늘)',
    daily_active_users_tip: '오늘 앱을 통하여 Token을 가져간 사용자 수',
    weekly_active_users: '활성화 사용자 (최근 7일)',
    weekly_active_users_tip: '최근 7일 동안 앱을 통하여 Token을 가져간 사용자 수',
    monthly_active_users: '활성화 사용자 (최근 30일)',
    monthly_active_users_tip: '최근 30일 동안 앱을 통하여 Token을 가져간 사용자 수',
};
export default dashboard;
