const get_started = {
    progress: 'Get started guide: {{completed}}/{{total}}',
    progress_dropdown_title: 'A few things you can do...',
    title: 'How do you want to get started with Logto?',
    subtitle_part1: 'A few things you can do to quickly get value of Logto',
    subtitle_part2: 'I’m done with this set up. ',
    hide_this: 'Hide this',
    confirm_message: 'Are you sure you want to hide this page? This action cannot be undone.',
    card1_title: 'Check out the demo',
    card1_subtitle: 'Try Logto sign-in experience now to see how it works',
    card2_title: 'Create and integrate the first application',
    card2_subtitle: 'Set up a mobile, single page or traditional application to use Logto for authentication',
    card3_title: 'Customize sign-in experience',
    card3_subtitle: 'Customize the sign in UI to match your brand and view in real time',
    card4_title: 'Set up SMS and email connector',
    card4_subtitle: 'Try passwordless sign in with phone number or email to enable a secure and frictionless customer experience',
    card5_title: 'Add a social connector',
    card5_subtitle: 'Let your customers sign in to your app with the social identities in one click',
    card6_title: 'Further readings',
    card6_subtitle: 'Check out our step-by-step, scenario-based docs without tedious concepts',
};
export default get_started;
