// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
const createGuard = z.object({
    roleId: z.string().max(21).nullable().optional(),
    scopeId: z.string().max(21).nullable().optional(),
});
const guard = z.object({
    roleId: z.string().max(21).nullable(),
    scopeId: z.string().max(21).nullable(),
});
export const RolesScopes = Object.freeze({
    table: 'roles_scopes',
    tableSingular: 'roles_scope',
    fields: {
        roleId: 'role_id',
        scopeId: 'scope_id',
    },
    fieldKeys: ['roleId', 'scopeId'],
    createGuard,
    guard,
});
