const logs = {
    title: 'Audit Logs',
    subtitle: 'Anzeige der Log Daten von Authentifizierungsereignissen, die von Admins und Benutzern stammen',
    event: 'Event',
    user: 'Benutzer',
    application: 'Anwendung',
    time: 'Zeit',
    filter_by: 'Filter nach',
};
export default logs;
