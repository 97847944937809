const get_started = {
    progress: 'Erste Schritte: {{completed}}/{{total}}',
    progress_dropdown_title: 'Was du machen kannst...',
    title: 'Wie willst du mit Logto loslegen?',
    subtitle_part1: 'Ein paar Dinge, die du tun kannst, um schnell von Logto zu profitieren',
    subtitle_part2: 'Ich bin fertig mit der Einrichtung.',
    hide_this: 'Ausblenden',
    confirm_message: 'Bist du sicher, dass du diese Seite ausblenden willst? Diese Aktion kann nicht rückgängig gemacht werden.',
    card1_title: 'Zur Demo',
    card1_subtitle: 'Probiere die Logto-Anmeldung jetzt aus, um zu sehen, wie sie funktioniert',
    card2_title: 'Erste Anwendung erstellen und integrieren',
    card2_subtitle: 'Richte eine native, Single Page oder herkömmliche Anwendung ein, die Logto zur Authentifizierung nutzt.',
    card3_title: 'Anmeldeoberfläche anpassen',
    card3_subtitle: 'Passe die Benutzeroberfläche für die Anmeldung an deine Marke an und zeige eine Vorschau in Echtzeit an',
    card4_title: 'SMS- und E-Mail-Verbindung einrichten',
    card4_subtitle: 'Probiere die passwortlose Anmeldung mit Telefonnummer oder E-Mail aus, um ein sicheres und reibungsloses Kundenerlebnis zu ermöglichen.',
    card5_title: 'Social Connector hinzufügen',
    card5_subtitle: 'Lass deine Kunden sich mit einem Klick mit ihren sozialen Identitäten bei deiner App anmelden',
    card6_title: 'Weitere Informationen',
    card6_subtitle: 'Schau dir unsere schrittweisen, szenariobasierten Dokumentationen ohne langweilige Konzepte an',
};
export default get_started;
