@use '@/scss/underscore' as _;

.container {
  .notice {
    margin: _.unit(4) 0 0;
  }

  a {
    color: var(--color-text-link);
    text-decoration: none;
  }
}
