const users = {
    title: '사용자 관리',
    subtitle: '사용자의 신원을 추가, 삭제, 수정, 조회하여 관리해보세요.',
    create: '사용자 추가',
    user_name: '사용자',
    application_name: '어플리케이션으로 부터',
    latest_sign_in: '최근 로그인 시각',
    create_form_username: '사용자 이름',
    create_form_password: '비밀번호',
    create_form_name: '이름',
    unnamed: '이름없음',
};
export default users;
