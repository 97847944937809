const get_started = {
    progress: 'Guide de démarrage : {{completed}}/{{total}}',
    progress_dropdown_title: 'Il y a quelques choses que vous pouvez faire...',
    title: 'Comment voulez-vous démarrer avec Logto ?',
    subtitle_part1: 'Voici quelques mesures que vous pouvez prendre pour tirer rapidement profit de Logto',
    subtitle_part2: "J'en ai fini avec cette installation. ",
    hide_this: 'Cacher cela',
    confirm_message: 'Êtes-vous sûr de vouloir masquer cette page ? Cette action ne peut être annulée.',
    card1_title: 'Regardez la démo',
    card1_subtitle: "Essayez maintenant l'expérience de connexion Logto pour voir comment elle fonctionne.",
    card2_title: 'Créer et intégrer la première application',
    card2_subtitle: "Configurez une application mobile, une page unique ou une application traditionnelle pour utiliser Logto pour l'authentification.",
    card3_title: "Personnaliser l'expérience de connexion",
    card3_subtitle: "Personnalisez l'interface utilisateur pour qu'elle corresponde à votre marque et consultez-la en temps réel.",
    card4_title: 'Configurer le connecteur SMS et e-mail',
    card4_subtitle: "Essayez de vous connecter sans mot de passe à l'aide d'un numéro de téléphone ou d'une adresse email pour offrir une expérience client sécurisée et sans friction.",
    card5_title: 'Ajouter un connecteur social',
    card5_subtitle: 'Permettez à vos clients de se connecter à votre application avec leurs identités sociales en un clic.',
    card6_title: 'Informations complémentaires',
    card6_subtitle: 'Découvrez nos documents basés sur des scénarios, étape par étape, sans concepts fastidieux',
};
export default get_started;
