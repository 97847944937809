const user_details = {
    back_to_users: 'Kullanıcı Yönetimine Geri Dön',
    created_title: 'Bu kullanıcı başarıyla oluşturuldu',
    created_guide: 'Kullanıcıya aşağıdaki oturum açma bilgilerini gönderebilirsiniz',
    created_username: 'Kullanıcı Adı:',
    created_password: 'Şifre:',
    menu_delete: 'Sil',
    delete_description: 'Bu işlem geri alınamaz. Kullanıcıyı kalıcı olarak siler.',
    deleted: 'Kullanıcı başarıyla silindi.',
    reset_password: {
        reset_password: 'Şifreyi sıfırla',
        title: 'Şifreyi sıfırlamak istediğinizden emin misiniz?',
        content: 'Bu işlem geri alınamaz. Bu, kullanıcının oturum açma bilgilerini sıfırlayacaktır.',
        congratulations: 'Bu kullanıcı sıfırlandı',
        new_password: 'Yeni şifre:',
    },
    tab_logs: 'Kullanıcı kayıtları',
    settings: 'Settings',
    settings_description: 'Each user has a profile containing all user information. It consists of basic data, social identities, and custom data.',
    field_email: 'Öncelikli e-posta adresi',
    field_phone: 'Öncelikli telefon',
    field_username: 'Kullanıcı Adı',
    field_name: 'İsim',
    field_avatar: 'Avatar resmi URLi',
    field_avatar_placeholder: 'https://your.cdn.domain/avatar.png',
    field_custom_data: 'Özel veriler',
    field_custom_data_tip: 'Kullanıcı tarafından tercih edilen renk ve dil gibi önceden tanımlanmış kullanıcı özelliklerinde listelenmeyen ek kullanıcı bilgileri.',
    field_connectors: 'Social connectors',
    custom_data_invalid: 'Özel veriler geçerli bir JSON nesnesi olmalıdır',
    connectors: {
        connectors: 'Connectors',
        user_id: 'Kullanıcı IDsi',
        remove: 'Kaldır',
        not_connected: 'Kullanıcı herhangi bir social connectora bağlı değil',
        deletion_confirmation: 'Mevcut <name/> kimliğini kaldırıyorsunuz. Bunu yapmak istediğinizden emin misiniz?',
    },
    suspended: 'Suspended', // UNTRANSLATED
};
export default user_details;
