const api_resources = {
    title: 'API Resources',
    subtitle: 'Define APIs that you can consume from your authorized applications',
    create: 'Create API Resource',
    api_name: 'API name',
    api_name_placeholder: 'Enter your API name',
    api_identifier: 'API identifier',
    api_identifier_tip: 'The unique identifier to the API resource. It must be an absolute URI and has no fragment (#) component. Equals to the resource parameter in OAuth 2.0.',
    api_resource_created: 'The API resource {{name}} has been successfully created',
    api_identifier_placeholder: 'https://your-api-identifier/',
};
export default api_resources;
