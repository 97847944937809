@use '@/scss/underscore' as _;

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.filter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: _.unit(3);
  border-bottom: 1px solid var(--color-divider);
  background-color: var(--color-layer-1);
  border-radius: 12px 12px 0 0;

  .title {
    color: var(--color-text-secondary);
    font: var(--font-body-medium);
  }

  .eventSelector {
    width: 300px;
    margin-left: _.unit(2);
  }

  .applicationSelector {
    width: 250px;
    margin-left: _.unit(2);
  }
}

.tableLayout {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
  flex: 1;

  .tableContainer {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.pagination {
  margin-top: _.unit(4);
}

.eventName {
  width: 360px;
}
