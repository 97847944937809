@use '@/scss/underscore' as _;

.preview {
  min-width: 480px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: var(--color-layer-1);
  border-radius: _.unit(4);

  .header {
    display: flex;
    margin: _.unit(6) _.unit(6) _.unit(1);

    .title {
      font: var(--font-subhead-1);
      flex: 1;
    }

    .selects {
      display: flex;
      align-items: center;

      > * {
        width: 104px;

        &:first-child {
          margin-right: _.unit(2);
        }
      }
    }
  }

  .nav {
    padding: 0 _.unit(6) 1px;
    margin-top: _.unit(6);
    border-bottom: unset;
  }

  .body {
    flex: 1;
    background: var(--color-surface-variant);

    iframe {
      border: none;
    }

    &.web {
      .device {
        width: 480px;
        height: 380px;
        position: relative;
        background: var(--color-surface-1);
        margin: 0 auto;

        iframe {
          width: 960px;
          height: 760px;
          transform: scale(0.5);
          position: absolute;
          top: -190px;
          left: -240px;
          background: var(--color-surface-1);
        }
      }
    }

    &.mobile {
      padding: _.unit(10) 0;
      height: 500px;
      position: relative;

      .deviceWrapper {
        width: 390px;
        height: 450px;
        margin: 0 auto;
        transform: scale(0.5);
        transform-origin: top center;

        .device {
          border-radius: 26px;
          overflow: hidden;

          .topBar {
            display: flex;
            align-items: center;
            padding: _.unit(3) _.unit(6);

            .time {
              flex: 1;
              font: var(--font-subhead-2);
            }
          }

          &.dark {
            // Sync with iframe's UI color
            background: #1a1c1d;

            .topBar {
              color: #fff;
            }
          }

          &.light {
            // Sync with iframe's UI color
            background: #fff;

            .topBar {
              color: #000;
            }
          }

          iframe {
            width: 390px;
            height: 808px;
          }
        }

        @media screen and (min-height: 1100px) {
          transform: unset;
          height: 900px;
        }

        @media screen and (min-height: 900px) and (max-height: 1100px) {
          transform: scale(0.75);
          height: 675px;
        }
      }
    }
  }
}
