const users = {
    title: '用户管理',
    subtitle: '管理你的用户，包括创建新用户，编辑用户资料，查看用户日志，以及重新设置密码和删除用户',
    create: '添加用户',
    user_name: '用户',
    application_name: '注册应用',
    latest_sign_in: '最后登录',
    create_form_username: '用户名',
    create_form_password: '密码',
    create_form_name: '姓名',
    unnamed: '未命名',
};
export default users;
