const dashboard = {
    title: 'Tableau de bord',
    description: "Obtenez une vue d'ensemble des performances de votre application",
    total_users: 'Utilisateurs totaux',
    total_users_tip: 'Utilisateurs totaux',
    new_users_today: "Nouveaux utilisateurs aujourd'hui",
    new_users_today_tip: "Le nombre de nouveaux utilisateurs enregistrés sur vos applications aujourd'hui",
    new_users_7_days: 'Nouveaux utilisateurs des 7 derniers jours',
    new_users_7_days_tip: 'Le nombre de nouveaux utilisateurs enregistrés sur vos applications au cours des 7 derniers jours.',
    daily_active_users: 'Utilisateurs actifs quotidiens',
    daily_active_users_tip: "Le nombre d'utilisateurs uniques qui ont échangé des jetons sur vos applications aujourd'hui.",
    weekly_active_users: 'Utilisateurs actifs hebdomadaires',
    weekly_active_users_tip: "Le nombre d'utilisateurs uniques ayant échangé des jetons sur vos applications au cours des 7 derniers jours.",
    monthly_active_users: 'Utilisateurs actifs mensuels',
    monthly_active_users_tip: "Le nombre d'utilisateurs uniques ayant échangé des jetons sur vos applications au cours des 30 derniers jours.",
};
export default dashboard;
