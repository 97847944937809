import { z } from 'zod';
// Alteration state
export var AlterationStateKey;
(function (AlterationStateKey) {
    AlterationStateKey["AlterationState"] = "alterationState";
})(AlterationStateKey || (AlterationStateKey = {}));
export const alterationStateGuard = Object.freeze({
    [AlterationStateKey.AlterationState]: z.object({
        timestamp: z.number(),
        updatedAt: z.string().optional(),
    }),
});
// Logto OIDC config
export var LogtoOidcConfigKey;
(function (LogtoOidcConfigKey) {
    LogtoOidcConfigKey["PrivateKeys"] = "oidc.privateKeys";
    LogtoOidcConfigKey["CookieKeys"] = "oidc.cookieKeys";
    LogtoOidcConfigKey["RefreshTokenReuseInterval"] = "oidc.refreshTokenReuseInterval";
})(LogtoOidcConfigKey || (LogtoOidcConfigKey = {}));
export const logtoOidcConfigGuard = Object.freeze({
    [LogtoOidcConfigKey.PrivateKeys]: z.string().array(),
    [LogtoOidcConfigKey.CookieKeys]: z.string().array(),
    /**
     * This interval helps to avoid concurrency issues when exchanging the rotating refresh token multiple times within a given timeframe.
     * During the leeway window (in seconds), the consumed refresh token will be considered as valid.
     * This is useful for distributed apps and serverless apps like Next.js, in which there is no shared memory.
     */
    [LogtoOidcConfigKey.RefreshTokenReuseInterval]: z.number().gte(3),
});
export const logtoConfigKeys = Object.freeze([
    ...Object.values(AlterationStateKey),
    ...Object.values(LogtoOidcConfigKey),
]);
export const logtoConfigGuards = Object.freeze({
    ...alterationStateGuard,
    ...logtoOidcConfigGuard,
});
