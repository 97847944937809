@use '@/scss/underscore' as _;

.tipBubble {
  position: relative;
  border-radius: 8px;
  background: var(--color-tooltip-background);
  color: var(--color-tooltip-text);
  box-shadow: var(--shadow-1);
  padding: _.unit(2) _.unit(3);
  font: var(--font-body-medium);
  max-width: 300px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: inherit;
    border-radius: _.unit(0.5) 0 _.unit(0.5);
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &.top::after {
    top: 100%;
  }

  &.right::after {
    top: 50%;
    left: 0%;
  }

  &.bottom::after {
    top: 0%;
  }

  &.left::after {
    top: 50%;
    left: 100%;
  }

  &.start::after {
    left: _.unit(10);
  }


  &.center::after {
    left: 50%;
  }

  &.end::after {
    right: _.unit(7.5);
  }
}
