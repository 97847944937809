import { hexColorRegEx } from '@logto/core-kit';
import { languageTagGuard } from '@logto/language-kit';
import { z } from 'zod';
export { configurableConnectorMetadataGuard, } from '@logto/connector-kit';
/**
 * Commonly Used
 */
// Cannot declare `z.object({}).catchall(z.unknown().optional())` to guard `{ [key: string]?: unknown }` (invalid type),
// so do it another way to guard `{ [x: string]: unknown; } | {}`.
export const arbitraryObjectGuard = z.union([z.object({}).catchall(z.unknown()), z.object({})]);
/**
 * OIDC Model Instances
 */
export const oidcModelInstancePayloadGuard = z
    .object({
    userCode: z.string().optional(),
    uid: z.string().optional(),
    grantId: z.string().optional(),
})
    /**
     * Try to use `.passthrough()` if type has been fixed.
     * https://github.com/colinhacks/zod/issues/452
     */
    .catchall(z.unknown());
// Import from @logto/core-kit later, pending for new version publish
export const webRedirectUriProtocolRegEx = /^https?:$/;
export const mobileUriSchemeProtocolRegEx = /^[a-z][\d_a-z]*(\.[\d_a-z]+)+:$/;
export const validateRedirectUrl = (urlString, type) => {
    try {
        const { protocol } = new URL(urlString);
        const protocolRegEx = type === 'mobile' ? mobileUriSchemeProtocolRegEx : webRedirectUriProtocolRegEx;
        return protocolRegEx.test(protocol);
    }
    catch {
        return false;
    }
};
export const oidcClientMetadataGuard = z.object({
    redirectUris: z
        .string()
        .refine((url) => validateRedirectUrl(url, 'web'))
        .or(z.string().refine((url) => validateRedirectUrl(url, 'mobile')))
        .array(),
    postLogoutRedirectUris: z.string().url().array(),
    logoUri: z.string().optional(),
});
export var CustomClientMetadataKey;
(function (CustomClientMetadataKey) {
    CustomClientMetadataKey["CorsAllowedOrigins"] = "corsAllowedOrigins";
    CustomClientMetadataKey["IdTokenTtl"] = "idTokenTtl";
    CustomClientMetadataKey["RefreshTokenTtl"] = "refreshTokenTtl";
})(CustomClientMetadataKey || (CustomClientMetadataKey = {}));
export const customClientMetadataGuard = z.object({
    [CustomClientMetadataKey.CorsAllowedOrigins]: z.string().url().array().optional(),
    [CustomClientMetadataKey.IdTokenTtl]: z.number().optional(),
    [CustomClientMetadataKey.RefreshTokenTtl]: z.number().optional(),
});
/**
 * Users
 */
export const roleNamesGuard = z.string().array();
const identityGuard = z.object({
    userId: z.string(),
    details: z.object({}).optional(), // Connector's userinfo details, schemaless
});
export const identitiesGuard = z.record(identityGuard);
/**
 * SignIn Experiences
 */
export const colorGuard = z.object({
    primaryColor: z.string().regex(hexColorRegEx),
    isDarkModeEnabled: z.boolean(),
    darkPrimaryColor: z.string().regex(hexColorRegEx),
});
export var BrandingStyle;
(function (BrandingStyle) {
    BrandingStyle["Logo"] = "Logo";
    BrandingStyle["Logo_Slogan"] = "Logo_Slogan";
})(BrandingStyle || (BrandingStyle = {}));
export const brandingGuard = z.object({
    style: z.nativeEnum(BrandingStyle),
    logoUrl: z.string().url(),
    darkLogoUrl: z.string().url().optional(),
    slogan: z.string().optional(),
});
export const termsOfUseGuard = z.object({
    enabled: z.boolean(),
    contentUrl: z.string().url().optional().or(z.literal('')),
});
export const languageInfoGuard = z.object({
    autoDetect: z.boolean(),
    fallbackLanguage: languageTagGuard,
});
export var SignUpIdentifier;
(function (SignUpIdentifier) {
    SignUpIdentifier["Email"] = "email";
    SignUpIdentifier["Sms"] = "sms";
    SignUpIdentifier["Username"] = "username";
    SignUpIdentifier["EmailOrSms"] = "emailOrSms";
    SignUpIdentifier["None"] = "none";
})(SignUpIdentifier || (SignUpIdentifier = {}));
export const signUpGuard = z.object({
    identifier: z.nativeEnum(SignUpIdentifier),
    password: z.boolean(),
    verify: z.boolean(),
});
export var SignInIdentifier;
(function (SignInIdentifier) {
    SignInIdentifier["Email"] = "email";
    SignInIdentifier["Sms"] = "sms";
    SignInIdentifier["Username"] = "username";
})(SignInIdentifier || (SignInIdentifier = {}));
export const signInGuard = z.object({
    methods: z
        .object({
        identifier: z.nativeEnum(SignInIdentifier),
        password: z.boolean(),
        verificationCode: z.boolean(),
        isPasswordPrimary: z.boolean(),
    })
        .array(),
});
export const connectorTargetsGuard = z.string().array();
/**
 * Settings
 */
export var AppearanceMode;
(function (AppearanceMode) {
    AppearanceMode["SyncWithSystem"] = "system";
    AppearanceMode["LightMode"] = "light";
    AppearanceMode["DarkMode"] = "dark";
})(AppearanceMode || (AppearanceMode = {}));
export const adminConsoleConfigGuard = z.object({
    // Get started challenges
    demoChecked: z.boolean(),
    applicationCreated: z.boolean(),
    signInExperienceCustomized: z.boolean(),
    passwordlessConfigured: z.boolean(),
    socialSignInConfigured: z.boolean(),
    furtherReadingsChecked: z.boolean(),
});
export const translationGuard = z.lazy(() => z.record(z.string().or(translationGuard)));
