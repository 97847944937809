// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { translationGuard } from '../foundations/index.js';
const createGuard = z.object({
    languageTag: z.string().max(16),
    translation: translationGuard,
});
const guard = z.object({
    languageTag: z.string().max(16),
    translation: translationGuard,
});
export const CustomPhrases = Object.freeze({
    table: 'custom_phrases',
    tableSingular: 'custom_phrase',
    fields: {
        languageTag: 'language_tag',
        translation: 'translation',
    },
    fieldKeys: ['languageTag', 'translation'],
    createGuard,
    guard,
});
