const settings = {
    title: 'Einstellungen',
    description: 'Verwalte die globalen Einstellungen',
    settings: 'Einstellungen',
    custom_domain: 'Benutzerdefinierte Domain',
    language: 'Sprache',
    appearance: 'Darstellung',
    appearance_system: 'Synchonisiere mit Systemeinstellungen',
    appearance_light: 'Hell',
    appearance_dark: 'Dunkel',
    saved: 'Gespeichert!',
    change_password: 'Passwort ändern',
    change_password_description: 'Du kannst das Passwort für dieses Konto ändern. Du verwendest den aktuellen Benutzernamen mit dem neuen Passwort, um dich in der Admin Konsole anzumelden.',
    change_modal_title: 'Account Password ändern',
    change_modal_description: 'Du verwendest den aktuellen Benutzernamen mit dem neuen Passwort, um dich in der Admin Konsole anzumelden.',
    new_password: 'Neues Passwort',
    new_password_placeholder: 'Gib ein neues Passwort ein',
    confirm_password: 'Passwort bestätigen',
    confirm_password_placeholder: 'Bestätige das neue Passwort',
    password_changed: 'Passwort geändert!',
};
export default settings;
