const tabs = {
    get_started: 'Get Started',
    dashboard: 'Dashboard',
    applications: 'Applications',
    api_resources: 'API Resources',
    sign_in_experience: 'Sign-in Experience',
    connectors: 'Connectors',
    users: 'User Management',
    audit_logs: 'Audit Logs',
    docs: 'Docs',
    contact_us: 'Contact Us',
    settings: 'Settings',
};
export default tabs;
