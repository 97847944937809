const connector_details = {
    back_to_connectors: 'Back to Connectors',
    check_readme: 'Check README',
    settings: 'Settings',
    settings_description: 'Connectors play a critical role in Logto. With their help, Logto enables end-users to use passwordless registration or sign-in and the capabilities of signing in with social accounts.',
    save_error_empty_config: 'Please enter config',
    send: 'Send',
    send_error_invalid_format: 'Invalid input',
    edit_config_label: 'Enter your json here',
    test_email_sender: 'Test your email connector',
    test_sms_sender: 'Test your SMS connector',
    test_email_placeholder: 'Enter a test email address',
    test_sms_placeholder: 'Enter a test phone number',
    test_message_sent: 'Test message sent!',
    test_sender_description: 'You will receive a message if your json is rightly configured',
    options_change_email: 'Change email connector',
    options_change_sms: 'Change SMS connector',
    connector_deleted: 'The connector has been successfully deleted',
    type_email: 'Email connector',
    type_sms: 'SMS connector',
    type_social: 'Social connector',
    in_use_deletion_description: 'This connector is in use in your sign in experience. By deleting, <name/> sign in experience will be deleted in sign in experience settings.',
};
export default connector_details;
