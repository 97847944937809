const get_started = {
    progress: '시작 가이드: {{completed}}/{{total}}',
    progress_dropdown_title: '해야할 것들...',
    title: 'Logto를 어떻게 시작할까요?',
    subtitle_part1: 'Logto의 가치를 얻기 위해 해야할 것들이 있어요.',
    subtitle_part2: '설정을 마칠게요 ',
    hide_this: '가리기',
    confirm_message: '정말로 이 페이지를 가릴까요? 이 행동은 취소할 수 없어요.',
    card1_title: '체험해보기',
    card1_subtitle: 'Logto 로그인 경험을 체험해보세요.',
    card2_title: '첫 어플리케이션 생성 및 연동해보기',
    card2_subtitle: '모바일 앱 및 Single Page, Tranditional 웹에 Logto 인증을 적용해보세요.',
    card3_title: '로그인 경험 커스터마이징하기',
    card3_subtitle: '로그인 화면을 브랜드에 맞게 커스터마이징 그리고 실시간으로 확인해보세요.',
    card4_title: 'SMS/이메일 연동하기',
    card4_subtitle: 'SMS 또는 이메일을 통해 비밀번호가 없이, 그리고 더욱 안전한 로그인 경험을 사용자에게 제공해보세요.',
    card5_title: '소셜 연동',
    card5_subtitle: '사용자의 소셜 정보를 통해 한 번의 클릭으로 로그인할 수 있는 경험을 사용자에게 제공해보세요.',
    card6_title: '더욱 나아가서',
    card6_subtitle: '복잡하지 않은 단계별 시나리오 문서를 확인해보세요.',
};
export default get_started;
