const errors = {
    something_went_wrong: '알 수 없는 오류가 발생했어요.',
    page_not_found: '페이지를 찾을 수 없어요.',
    unknown_server_error: '서버에서 알 수 없는 오류가 발생했어요.',
    empty: '데이터 없음',
    missing_total_number: 'Total-Number를 응답 해더에서 찾을 수 없어요.',
    invalid_uri_format: 'URI 형식이 유효하지 않음',
    invalid_origin_format: 'URI origin 형식이 유효하지 않음',
    invalid_json_format: 'JSON 형식이 유효하지 않음',
    invalid_error_message_format: '오류 메세지 형식이 유효하지 않아요.',
    required_field_missing: '{{field}}을/를 입력해주세요.',
    required_field_missing_plural: '최소 1개의 {{field}}을/를 입력해야해요.',
    more_details: '자세히',
    username_pattern_error: '아이디는 반드시 문자, 숫자, _ 만으로 이루어져야 하며, 숫자로 시작하면 안되요.',
    password_pattern_error: '비밀번호는 최소 6자리로 이루어져야해요.',
    insecure_contexts: '비보안 연결(non-HTTPS)는 지원하지 않아요.',
    unexpected_error: '알 수 없는 오류가 발생했어요.',
};
export default errors;
