@use '@/scss/underscore' as _;

.container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .cardTitle {
    flex-shrink: 0;
  }

  >:not(:first-child) {
    margin-top: _.unit(4);
  }
}
