const api_resource_details = {
    back_to_api_resources: '返回 API 资源',
    settings: '设置',
    settings_description: 'API resources, a.k.a. Resource Indicators, indicate the target services or resources to be requested, usually, a URI format variable representing the resource‘s identity.',
    token_expiration_time_in_seconds: 'Token 过期时间（秒）',
    token_expiration_time_in_seconds_placeholder: '请输入你的 token 过期时间',
    delete_description: '本操作会永久性地删除该 API 资源，且不可撤销。输入 API 资源名称 <span>{{name}}</span> 确认。',
    enter_your_api_resource_name: '输入 API 资源名称',
    api_resource_deleted: ' API 资源 {{name}} 已删除.',
};
export default api_resource_details;
