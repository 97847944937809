import { AppearanceMode } from '../foundations/index.js';
export const defaultSettingId = 'default';
export const createDefaultSetting = () => Object.freeze({
    id: defaultSettingId,
    adminConsole: {
        language: 'en',
        appearanceMode: AppearanceMode.SyncWithSystem,
        demoChecked: false,
        applicationCreated: false,
        signInExperienceCustomized: false,
        passwordlessConfigured: false,
        socialSignInConfigured: false,
        furtherReadingsChecked: false,
    },
});
