// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { arbitraryObjectGuard } from '../foundations/index.js';
const createGuard = z.object({
    id: z.string().max(21),
    type: z.string().max(64),
    payload: arbitraryObjectGuard.optional(),
    createdAt: z.number().optional(),
});
const guard = z.object({
    id: z.string().max(21),
    type: z.string().max(64),
    payload: arbitraryObjectGuard,
    createdAt: z.number(),
});
export const Logs = Object.freeze({
    table: 'logs',
    tableSingular: 'log',
    fields: {
        id: 'id',
        type: 'type',
        payload: 'payload',
        createdAt: 'created_at',
    },
    fieldKeys: ['id', 'type', 'payload', 'createdAt'],
    createGuard,
    guard,
});
