// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
const createGuard = z.object({
    id: z.string().max(21),
    name: z.string(),
    indicator: z.string(),
    accessTokenTtl: z.number().optional(),
});
const guard = z.object({
    id: z.string().max(21),
    name: z.string(),
    indicator: z.string(),
    accessTokenTtl: z.number(),
});
export const Resources = Object.freeze({
    table: 'resources',
    tableSingular: 'resource',
    fields: {
        id: 'id',
        name: 'name',
        indicator: 'indicator',
        accessTokenTtl: 'access_token_ttl',
    },
    fieldKeys: ['id', 'name', 'indicator', 'accessTokenTtl'],
    createGuard,
    guard,
});
