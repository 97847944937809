const log_details = {
    back_to_logs: 'Denetim kaydına geri dön',
    back_to_user: '{{name}}e geri dön',
    success: 'Başarılı',
    failed: 'Başarısız',
    event_type: 'Etkinlik tipi',
    application: 'Uygulama',
    ip_address: 'IP adresi',
    user: 'Kullanıcı',
    log_id: 'Kayıt Kimliği',
    time: 'Süre',
    user_agent: 'Kullanıcı aracısı',
    tab_details: 'Detaylar',
    raw_data: 'Ham veri',
};
export default log_details;
