export const userInfoSelectFields = Object.freeze([
    'id',
    'username',
    'primaryEmail',
    'primaryPhone',
    'name',
    'avatar',
    'roleNames',
    'customData',
    'identities',
    'lastSignInAt',
    'createdAt',
    'applicationId',
    'isSuspended',
]);
export var UserRole;
(function (UserRole) {
    UserRole["Admin"] = "admin";
})(UserRole || (UserRole = {}));
