const api_resource_details = {
    back_to_api_resources: 'API Kaynaklarına geri dön',
    settings: 'Settings',
    settings_description: 'API resources, a.k.a. Resource Indicators, indicate the target services or resources to be requested, usually, a URI format variable representing the resource‘s identity.',
    token_expiration_time_in_seconds: 'Token sona erme süresi (saniye)',
    token_expiration_time_in_seconds_placeholder: 'Token zaman aşım süresini giriniz',
    delete_description: 'Bu eylem geri alınamaz. API kaynakları kalıcı olarak silinecektir. Lütfen onaylamak için API kaynak adını <span>{{name}}</span> giriniz.',
    enter_your_api_resource_name: 'API kaynak adını giriniz.',
    api_resource_deleted: '{{name}} API kaynağı başarıyla silindi',
};
export default api_resource_details;
