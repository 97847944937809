// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { adminConsoleConfigGuard } from '../foundations/index.js';
const createGuard = z.object({
    id: z.string().max(21),
    adminConsole: adminConsoleConfigGuard,
});
const guard = z.object({
    id: z.string().max(21),
    adminConsole: adminConsoleConfigGuard,
});
export const Settings = Object.freeze({
    table: 'settings',
    tableSingular: 'setting',
    fields: {
        id: 'id',
        adminConsole: 'admin_console',
    },
    fieldKeys: ['id', 'adminConsole'],
    createGuard,
    guard,
});
