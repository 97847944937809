const api_resource_details = {
    back_to_api_resources: 'Retour aux ressources API',
    settings: 'Settings',
    settings_description: 'API resources, a.k.a. Resource Indicators, indicate the target services or resources to be requested, usually, a URI format variable representing the resource‘s identity.',
    token_expiration_time_in_seconds: "Temps d'expiration du jeton (en secondes)",
    token_expiration_time_in_seconds_placeholder: "Entrez le délai d'expiration de votre jeton",
    delete_description: 'Cette action ne peut pas être annulée. Elle supprimera définitivement la ressource API. Veuillez entrer le nom de la ressource API <span>{{name}}</span> pour confirmer.',
    enter_your_api_resource_name: 'Entrez le nom de votre ressource API',
    api_resource_deleted: 'La ressource API {{name}} a été supprimée avec succès',
};
export default api_resource_details;
