import { generateDarkColor } from '@logto/core-kit';
import { SignInMode } from '../db-entries/index.js';
import { BrandingStyle, SignInIdentifier, SignUpIdentifier } from '../foundations/index.js';
const defaultPrimaryColor = '#6139F6';
export const defaultSignInExperience = {
    id: 'default',
    color: {
        primaryColor: defaultPrimaryColor,
        isDarkModeEnabled: false,
        darkPrimaryColor: generateDarkColor(defaultPrimaryColor),
    },
    branding: {
        style: BrandingStyle.Logo,
        logoUrl: 'https://logto.io/logo.svg',
        darkLogoUrl: 'https://logto.io/logo-dark.svg',
    },
    languageInfo: {
        autoDetect: true,
        fallbackLanguage: 'en',
    },
    termsOfUse: {
        enabled: false,
    },
    signUp: {
        identifier: SignUpIdentifier.Username,
        password: true,
        verify: false,
    },
    signIn: {
        methods: [
            {
                identifier: SignInIdentifier.Username,
                password: true,
                verificationCode: false,
                isPasswordPrimary: true,
            },
        ],
    },
    socialSignInConnectorTargets: [],
    signInMode: SignInMode.SignInAndRegister,
};
export const adminConsoleSignInExperience = {
    ...defaultSignInExperience,
    color: {
        ...defaultSignInExperience.color,
        isDarkModeEnabled: true,
    },
    branding: {
        style: BrandingStyle.Logo_Slogan,
        logoUrl: 'https://logto.io/logo.svg',
        darkLogoUrl: 'https://logto.io/logo-dark.svg',
    },
};
