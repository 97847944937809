const tabs = {
    get_started: '开始上手',
    dashboard: '仪表盘',
    applications: '全部应用',
    api_resources: 'API 资源',
    sign_in_experience: '登录体验',
    connectors: '连接器',
    users: '用户管理',
    audit_logs: '审计日志',
    docs: '文档',
    contact_us: '联系我们',
    settings: '设置',
};
export default tabs;
