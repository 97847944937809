// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
const createGuard = z.object({
    id: z.string().max(21),
    resourceId: z.string().max(21).nullable().optional(),
    name: z.string().max(256),
    description: z.string().nullable().optional(),
    createdAt: z.number().optional(),
});
const guard = z.object({
    id: z.string().max(21),
    resourceId: z.string().max(21).nullable(),
    name: z.string().max(256),
    description: z.string().nullable(),
    createdAt: z.number(),
});
export const Scopes = Object.freeze({
    table: 'scopes',
    tableSingular: 'scope',
    fields: {
        id: 'id',
        resourceId: 'resource_id',
        name: 'name',
        description: 'description',
        createdAt: 'created_at',
    },
    fieldKeys: ['id', 'resourceId', 'name', 'description', 'createdAt'],
    createGuard,
    guard,
});
