const connectors = {
    title: 'Connectorlar',
    subtitle: 'Şifresiz ve sosyal oturum açma deneyimini etkinleştirmek için connectorları ayarlayınız.',
    create: 'Social Connector ekle',
    config_sie_notice: 'You’ve set up connectors. Make sure to configure it in <a>{{link}}</a>.',
    config_sie_link_text: 'sign in experience',
    tab_email_sms: 'E-posta ve SMS connectorları',
    tab_social: 'Social connectorlar',
    connector_name: 'Connector adı',
    connector_type: 'Tip',
    connector_status: 'Oturum açma deneyimi',
    connector_status_in_use: 'Kullanımda',
    connector_status_not_in_use: 'Kullanımda değil',
    not_in_use_tip: {
        content: 'Not in use means your sign in experience hasn’t used this sign in method. <a>{{link}}</a> to add this sign in method. ',
        go_to_sie: 'Go to sign in experience', // UNTRANSLATED
    },
    social_connector_eg: 'Örneğin, Google, Facebook, Github',
    save_and_done: 'Kaydet ve bitir',
    type: {
        email: 'Eposta connectorı',
        sms: 'SMS connectorı',
        social: 'Social connector',
    },
    setup_title: {
        email: 'Eposta connectorı ayarla',
        sms: 'SMS connectorı ayarla',
        social: 'Social Connector ekle',
    },
    guide: {
        subtitle: 'Connectorı yapılandırmak için adım adım kılavuz',
    },
    platform: {
        universal: 'Evrensel',
        web: 'Web',
        native: 'Native',
    },
    add_multi_platform: ' birden fazla platformu destekler, devam etmek için bir platform seçin',
    drawer_title: 'Connector Kılavuzu',
    drawer_subtitle: 'Connectorı entegre etmek için yönergeleri izleyin',
};
export default connectors;
