import { emailRegEx, phoneRegEx, usernameRegEx, passwordRegEx } from '@logto/core-kit';
import { z } from 'zod';
/**
 * Detailed Identifier Methods guard
 */
export const usernamePasswordPayloadGuard = z.object({
    username: z.string().min(1),
    password: z.string().min(1),
});
export const emailPasswordPayloadGuard = z.object({
    email: z.string().min(1),
    password: z.string().min(1),
});
export const phonePasswordPayloadGuard = z.object({
    phone: z.string().min(1),
    password: z.string().min(1),
});
export const emailPasscodePayloadGuard = z.object({
    email: z.string().regex(emailRegEx),
    passcode: z.string().min(1),
});
export const phonePasscodePayloadGuard = z.object({
    phone: z.string().regex(phoneRegEx),
    passcode: z.string().min(1),
});
export const socialConnectorPayloadGuard = z.object({
    connectorId: z.string(),
    connectorData: z.unknown(),
});
/**
 * Interaction Payload Guard
 */
export const eventGuard = z.union([
    z.literal('sign-in'),
    z.literal('register'),
    z.literal('forgot-password'),
]);
export const identifierGuard = z.union([
    usernamePasswordPayloadGuard,
    emailPasswordPayloadGuard,
    phonePasswordPayloadGuard,
    emailPasscodePayloadGuard,
    phonePasscodePayloadGuard,
    socialConnectorPayloadGuard,
]);
export const profileGuard = z.object({
    username: z.string().regex(usernameRegEx).optional(),
    email: z.string().regex(emailRegEx).optional(),
    phone: z.string().regex(phoneRegEx).optional(),
    connectorId: z.string().optional(),
    password: z.string().regex(passwordRegEx).optional(),
});
