const contact = {
    title: '연락처',
    description: '커뮤니티에 참여하여 피드백을 제공하고 도움을 요청하며 다른 개발자와 생각을 공유해보세요.',
    discord: {
        title: 'Discord 채널',
        description: '공개 채널에 참여하여 다른 개발자와 채팅해보세요.',
        button: '참가',
    },
    github: {
        title: 'GitHub',
        description: 'GitHub에서 이슈를 생성해보세요.',
        button: '열기',
    },
    email: {
        title: '이메일 보내기',
        description: '추가 정보 및 도움말을 보려면 이메일을 보내주세요.',
        button: '보내기',
    },
};
export default contact;
