@use '@/scss/underscore' as _;

.draggleItemContainer {
  transform: translate(0, 0);
}

.setUpHint {
  font: var(--font-body-medium);
  color: var(--color-text-secondary);
  margin-top: _.unit(2);

  a {
    color: var(--color-text-link);
    text-decoration: none;
  }
}
