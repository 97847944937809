const tabs = {
    get_started: 'Começo',
    dashboard: 'Painel',
    applications: 'Aplicações',
    api_resources: 'Recursos API',
    sign_in_experience: 'Experiência de login',
    connectors: 'Conectores',
    users: 'Gestão de utilizadores',
    audit_logs: 'Registos de auditoria',
    docs: 'Documentação',
    contact_us: 'Contate-nos',
    settings: 'Definições',
};
export default tabs;
