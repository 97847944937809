const get_started = {
    progress: 'Guia de primeiros passos: {{completed}}/{{total}}',
    progress_dropdown_title: 'Algumas coisas que pode fazer...',
    title: 'Como deseja começar a usar o Logto?',
    subtitle_part1: 'Algumas coisas que pode fazer para obter rapidamente o valor do Logto',
    subtitle_part2: 'Acabei com esta configuração. ',
    hide_this: 'Ocultar isto',
    confirm_message: 'Tem a certeza que deseja ocultar esta página? Esta ação não pode ser desfeita.',
    card1_title: 'Confira a demonstração',
    card1_subtitle: 'Experimente a experiência de login do Logto agora para ver como funciona',
    card2_title: 'Crie e integre a primeira aplicação',
    card2_subtitle: 'Configure um aplicativo móvel, de página única ou tradicional para usar o Logto para autenticação',
    card3_title: 'Personalize a experiência de login',
    card3_subtitle: 'Personalize a interface de login para corresponder a sua marca e visualize em tempo rea',
    card4_title: 'Configure um conector de SMS/Email',
    card4_subtitle: 'Experimente o login sem senha com número de telefone ou email para permitir uma experiência do cliente segura e sem atritos',
    card5_title: 'Adicione um conector social',
    card5_subtitle: 'Permita que os seus clientes entrem com as identidades sociais em um clique',
    card6_title: 'Outras leituras',
    card6_subtitle: 'Confira a nossa documentação passo a passo baseados em cenários sem conceitos tediosos',
};
export default get_started;
