@use '@/scss/underscore' as _;

.filter {
  padding: _.unit(3);
  background-color: var(--color-layer-1);
  border-bottom: 1px solid var(--color-divider);
  border-radius: 12px 12px 0 0;
}

.tableLayout {
  display: flex;
  flex-direction: column;

  .tableContainer {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.pagination {
  margin-top: _.unit(4);
}

.avatar {
  width: 28px;
  height: 28px;
  border-radius: 6px;
  object-fit: cover;
  flex-shrink: 0;
}

.userName {
  width: 360px;
  font: var(--font-body-medium);
}
