const connector_details = {
    back_to_connectors: 'Connectorlara dön',
    check_readme: 'READMEye göz at',
    settings: 'Settings',
    settings_description: 'Connectors play a critical role in Logto. With their help, Logto enables end-users to use passwordless registration or sign-in and the capabilities of signing in with social accounts.',
    save_error_empty_config: 'Lütfen yapılandırmayı girin',
    send: 'Gönder',
    send_error_invalid_format: 'Geçersiz input',
    edit_config_label: 'json girin ',
    test_email_sender: 'eposta connectorunuzu test edin',
    test_sms_sender: 'SMS connectorunuzu test edin',
    test_email_placeholder: 'Test e-posta adresi girin',
    test_sms_placeholder: 'Test telefon numarası girin',
    test_message_sent: 'Test mesajı gönderildi!',
    test_sender_description: 'Eğer jsonunuz doğru yapılandırılmışsa bir mesaj alacaksınız',
    options_change_email: 'Eposta connectorunuzu değiştirin',
    options_change_sms: 'SMS connectorunuzu değiştirin',
    connector_deleted: 'Connector başarıyla silindi',
    type_email: 'Eposta connectorı',
    type_sms: 'SMS connectorı',
    type_social: 'Social connector',
    in_use_deletion_description: 'This connector is in use in your sign in experience. By deleting, <name/> sign in experience will be deleted in sign in experience settings.', // UNTRANSLATED
};
export default connector_details;
