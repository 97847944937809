// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
const createGuard = z.object({
    id: z.string().max(21),
    name: z.string().max(128),
    description: z.string().max(128),
});
const guard = z.object({
    id: z.string().max(21),
    name: z.string().max(128),
    description: z.string().max(128),
});
export const Roles = Object.freeze({
    table: 'roles',
    tableSingular: 'role',
    fields: {
        id: 'id',
        name: 'name',
        description: 'description',
    },
    fieldKeys: ['id', 'name', 'description'],
    createGuard,
    guard,
});
