@use '@/scss/underscore' as _;

.container {
  height: 100%;
  min-height: 528px;
  padding-bottom: _.unit(6);
}

.welcome {
  height: 100%;
  display: flex;
  flex-direction: column;

  .cardTitle {
    flex-shrink: 0;
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: _.unit(4);

    > *:not(:last-child) {
      margin-bottom: _.unit(5);
    }

    .text {
      font: var(--font-body-medium);
      max-width: 410px;
      text-align: center;
    }
  }
}
