const demo_app = {
    notification: 'Demo uygulamaya giriş yapmak için yönetici konsolunu kullanınız.',
    title: 'Demo uygulamaya başarıyla giriş yaptınız!',
    subtitle: 'Sisteme giriş bilgileriniz:',
    username: 'Kullanıcı Adı: ',
    user_id: 'Kullanıcı Kimliği: ',
    sign_out: 'Demo uygulamadan çıkış yap',
    continue_explore: 'Veya keşfetmeye devam et',
    customize_sign_in_experience: 'Oturum Açma Deneyimini Özelleştir',
    enable_passwordless: 'Şifresiz Etkinleştir',
    add_social_connector: 'Social Connector Ekle',
};
export default demo_app;
